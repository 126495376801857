import KaleBrandsHelmet from "../utils/KaleBrandsHelmet";
import PaymentHistoryList from "../PaymentHistoryList";

export default function PaymentsSettingsPage() {
  return (
    <>
      <KaleBrandsHelmet pageName="Payment History" />
      <div className="mt-4">
        <PaymentHistoryList />
      </div>
    </>
  );
}

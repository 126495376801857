import { useQuery } from "@apollo/client";
import { BrandContentData, Challenge, Spinner } from "@kalecard/common";
import { useContext } from "react";
import { useLocation, useParams } from "react-router";
import { CHALLENGE, CONTENT } from "../../graphql/queries";
import { UserContext } from "../../providers/UserProvider";
import ContentProvider from "../../providers/ContentProvider";
import { contentSortingTypes } from "../../reducers/ContentReducer";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { renderChallengeState } from "./ChallengeData";
import ChallengeContent from "../content/ChallengeContent";
import { Link } from "react-router-dom";
import BrandReportDashboard from "../BrandReportDashboard";

interface ChallengeData {
  challenge: Challenge;
}

export default function ChallengeDetailView() {
  const params = useParams();
  const challengeId = params["id"];
  const location = useLocation();
  const { brandId } = useContext(UserContext);
  const isStats = location.pathname.includes("/stats");

  const { data: challengeData } = useQuery<ChallengeData>(CHALLENGE, {
    variables: {
      challengeId,
      brandId,
    },
  });

  const { loading: contentLoading, data } = useQuery<BrandContentData>(
    CONTENT,
    {
      variables: {
        brandId: brandId,
        after: "0",
        kalePicks: true,
        challengeIds: [challengeId],
      },
      skip: isStats,
    }
  );

  return (
    <div>
      <div className="mx-6 flex items-center justify-between border-b border-black border-opacity-10 pb-4">
        <div className="flex w-2/5 space-x-3">
          <Link
            className="text-2xl font-semibold text-gray-900 underline underline-offset-4"
            to="/challenges"
          >
            Challenges
          </Link>
          {challengeData?.challenge && (
            <div className="flex w-60 items-center space-x-2 font-normal text-black text-opacity-60">
              <div>
                <ChevronRightIcon className="static h-4 w-4" />
              </div>
              <p className="truncate text-2xl">
                {challengeData.challenge.description}
              </p>
            </div>
          )}
        </div>
        <div className="flex space-x-10 px-4">
          {challengeData?.challenge?.firstActivationDate && (
            <p className="text-center text-sm font-medium">Activated on</p>
          )}
          {challengeData?.challenge && (
            <p className="text-sm font-medium">State</p>
          )}
        </div>
      </div>
      {challengeData?.challenge && (
        <div className="mx-6 flex items-center justify-between pt-6">
          <div className="flex w-2/5 flex-col">
            <div className="text-left text-sm font-medium">
              {challengeData.challenge.description}
            </div>
            <div className="text-left text-xs font-medium text-gray-500">
              {challengeData.challenge.tagRequirements}
            </div>
          </div>
          <div className="flex items-center space-x-10">
            {challengeData.challenge.firstActivationDate && (
              <div className="text-center text-sm">
                {new Date(
                  Number(challengeData.challenge.firstActivationDate)
                ).toLocaleDateString()}
              </div>
            )}
            <div className="flex items-center justify-center space-x-1 rounded-full">
              <div>{renderChallengeState(challengeData.challenge.state)}</div>
            </div>
          </div>
        </div>
      )}
      {isStats ? (
        <div className="w-full pt-4">
          <BrandReportDashboard brandId={brandId} challengeId={challengeId} />
        </div>
      ) : !contentLoading ? (
        <ContentProvider
          contentState={{
            content: data?.content?.edges?.map((edge) => edge?.node),
            hasNextPage: data?.content?.pageInfo?.hasNextPage,
            after: data?.content?.edges?.length.toString(),
            challengeId: challengeId,
            sortingType: contentSortingTypes.find(
              (sortingType) => sortingType.id === "KALE_PICKS"
            ),
            shouldUpdateContent: false,
          }}
        >
          <ChallengeContent />
        </ContentProvider>
      ) : (
        <div className="flex justify-center pt-10">
          <Spinner size={"h-8 w-8"} />
        </div>
      )}
    </div>
  );
}

import { Brand, Challenge, Dropdown, Spinner } from "@kalecard/common";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { SUGGEST_CHALLENGE } from "../../graphql/mutations";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  CHALLENGE_CATEGORIES,
  CHALLENGE_SUGGESTIONS,
} from "../../graphql/queries";
import { ChallengeCategory } from "../../__generated__/graphql";

interface SuggestChallengeFormInterface {
  brand: Brand;
  onSubmit?: (suggestedChallenge: Challenge) => void;
}

type SuggestChallengeFormInputs = {
  note: string;
  exampleUrl: string;
};

function isValidLink(link) {
  const regex = /^https:\/\/.*/;
  return !link || regex.test(link);
}

export default function SuggestChallengeForm({
  brand,
  onSubmit,
}: SuggestChallengeFormInterface) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const date = new Date();

  const [selectedCategory, setSelectedCategory] = useState(null);

  const { reset, register, handleSubmit } =
    useForm<SuggestChallengeFormInputs>();

  const { data: challengeCategoriesData } = useQuery(CHALLENGE_CATEGORIES, {
    variables: {
      brandId: brand.id,
    },
    skip: brand.brandCategories.length === 0,
  });

  const [suggestChallenge] = useMutation(SUGGEST_CHALLENGE);

  const submit: SubmitHandler<SuggestChallengeFormInputs> = async (
    data: SuggestChallengeFormInputs
  ) => {
    if (data.exampleUrl && !isValidLink(data.exampleUrl)) {
      setError("Invalid URL format. Please enter an example URL that starts with 'https://'");
      return;
    }
    setIsLoading(true);
    try {
      const result = await suggestChallenge({
        variables: {
          brandId: brand.id,
          categoryId: selectedCategory?.id,
          note: data.note,
          exampleUrl: data.exampleUrl,
          startDate: startDate?.getTime(),
          endDate: endDate?.getTime(),
        },
        refetchQueries: [CHALLENGE_SUGGESTIONS],
      });
      console.log(result);
      setError(null);
      onSubmit(result.data.suggestChallenge);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <form className="w-full space-y-2">
      <div className="flex flex-col space-y-2">
        {/* Template Pick */}
        <label
          htmlFor="challengeTemplate"
          className="block text-sm font-medium text-gray-700"
        >
          Select a challenge theme:
        </label>
        {challengeCategoriesData?.challengeCategories && (
          <ChallengeThemes
            challengeCategories={challengeCategoriesData.challengeCategories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        )}
        {/* Note */}
        <div>
          <label
            htmlFor="note"
            className="block text-sm font-medium text-gray-700"
          >
            Use this space to provide additional details
          </label>
          <div className="mt-1 flex flex-col">
            <div className="flex flex-row">
              <textarea
                rows={3}
                {...register("note")}
                name="note"
                id="note"
                className="block w-full flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>
        {/* Example Url */}
        <div className="mt-2">
          <label
            htmlFor="exampleUrl"
            className="block text-sm font-medium text-gray-700"
          >
            Share a URL link to an example video:
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              {...register("exampleUrl")}
              name="exampleUrl"
              id="exampleUrl"
              className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        {/* Dates */}
        <div className="flex flex-col space-y-2">
          {/* Start Date */}
          <div>
            <label
              htmlFor="month"
              className="block text-sm font-medium text-gray-700"
            >
              <p>
                Set an activation date:
                <p className="font-normal italic">
                  If left blank, we'll activate as soon as it's reviewed (within
                  5 business days)
                </p>
              </p>
            </label>
            <DatePicker
              className="mt-1 rounded-md border-gray-300 text-sm shadow-sm"
              selected={startDate}
              showTimeSelect
              onChange={(date) => setStartDate(date)}
              dateFormat="MM/dd/yyyy HH:mm"
              minDate={new Date().setDate(date.getDate() + 1)}
            />
          </div>
          {/* End Date */}
          <div>
            <label
              htmlFor="month"
              className="block text-sm font-medium text-gray-700"
            >
              Set a deactivation date:
            </label>
            <DatePicker
              className="mt-1 rounded-md border-gray-300 text-sm shadow-sm"
              selected={endDate}
              showTimeSelect
              onChange={(date) => setEndDate(date)}
              dateFormat="MM/dd/yyyy HH:mm"
              minDate={
                startDate
                  ? new Date(startDate)
                  : new Date().setDate(date.getDate() + 1)
              }
            />
          </div>
        </div>

        <div className="flex justify-end space-x-2 pt-5">
          {isLoading && (
            <div className="flex flex-wrap content-center justify-center">
              <Spinner size="h-6 w-6" />
            </div>
          )}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleSubmit(submit)}
              disabled={isLoading || !selectedCategory}
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-400"
            >
              Submit Challenge Idea
            </button>
          </div>
        </div>
        <div className="mt-2 flex justify-end">
          <p className="font-bold text-red-500">{error}</p>
        </div>
      </div>
    </form>
  );
}

export function ChallengeThemes({
  challengeCategories,
  selectedCategory,
  setSelectedCategory,
}: {
  challengeCategories: ChallengeCategory[];
  selectedCategory?: ChallengeCategory;
  setSelectedCategory: (selectedCategory: ChallengeCategory) => void;
}) {
  return (
    <div className="flex flex-wrap gap-2">
      {challengeCategories.map((challengeCategory) => {
        const match = challengeCategory.name.trim().match(emojiPattern);
        const emoji = match ? match[0] : "⭐️";
        const templateName = match
          ? challengeCategory.name.trim().slice(0, -match[0].length).trim()
          : challengeCategory.name.trim();
        const isSelected = challengeCategory.id === selectedCategory?.id;

        return (
          <div
            key={challengeCategory.id}
            className={`flex h-20 w-20 flex-col border ${
              isSelected
                ? "border-kale-green-500 bg-kale-mint-500"
                : "border-kale-green-300 bg-kale-mint-300"
            } items-center justify-center rounded-md text-center hover:cursor-pointer`}
            onClick={() => setSelectedCategory(challengeCategory)}
          >
            <div className="text-xl">{emoji}</div>
            <div
              className={`text-xs ${isSelected ? "font-medium" : ""}`}
              style={{ wordBreak: "break-word" }}
            >
              <p>{templateName}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

const emojiPattern =
  /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{FE00}-\u{FE0F}|\u{1F004}|\u{1F0CF}|\u{1F18E}|\u{1F191}-\u{1F19A}|\u{1F1E6}-\u{1F1FF}|\u{1F201}-\u{1F251}|\u{1F300}-\u{1F5FF}|\u{1F600}-\u{1F64F}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{1FB00}-\u{1FBFF}|\u{1FC00}-\u{1FCFF}|\u{1FD00}-\u{1FDFF}|\u{1FE00}-\u{1FEFF}|\u{1FF00}-\u{1FFFF}|\u200D|\uFE0E|\uFE0F]+$/u;

import { useQuery } from "@apollo/client";
import BlockUsernamesForm from "../forms/BlockUsernamesForm";
import { BLOCKED_USERS } from "../../graphql/queries";
import { useContext, useRef, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import CSVBlockedUsers from "../utils/BlockedUsersCSV";
import InfluencerPosts from "./InfluencerPosts";

export default function InfluencerBlockList() {
  const { brandId } = useContext(UserContext);
  const csvLink = useRef(null);
  const {
    data: blockedUsersData,
    loading: blockedUsersLoading,
    refetch,
  } = useQuery(BLOCKED_USERS, {
    variables: { brandId: brandId },
  });
  return (
    <div className="space-y-0 divide-y divide-gray-200">
      <div className="mb-8">
        <h3 className="mt-4 text-lg font-medium text-gray-900">
          Block users from posting for your brand
        </h3>
        <BlockUsernamesForm refetchBlockedUsers={refetch} />
        {blockedUsersData?.blockedUsers?.length > 0 && !blockedUsersLoading && (
          <div className="flex items-center space-x-4 pt-4">
            <p className="text-sm font-medium text-gray-900">
              View your blocked users:
            </p>
            <div
              onClick={() => csvLink.current.link.click()}
              className="flex w-fit items-center space-x-2 rounded-md border border-transparent bg-kale-mint-500 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm hover:cursor-pointer hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-kale-green-500 focus:ring-offset-2"
            >
              <ArrowDownTrayIcon className="h-4 w-4" />
              <p>Download CSV</p>
            </div>
          </div>
        )}
        <CSVBlockedUsers
          blockedUsers={blockedUsersData?.blockedUsers ?? []}
          csvRef={csvLink}
        />
      </div>
      <div>
        <InfluencerPosts />
      </div>
    </div>
  );
}

import { useContext, useState } from "react";
import KaleBrandsHelmet from "../utils/KaleBrandsHelmet";
import { UserContext } from "../../providers/UserProvider";
import {
  Brand,
  prettyDecimal,
  Spinner,
  StripePaymentMethod,
} from "@kalecard/common";
import { Text, Strong } from "../catalyst/text";
import { Button } from "../catalyst/button";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useLazyQuery } from "@apollo/client";
import { INITIATE_CUSTOMER_PORTAL } from "../../graphql/queries";
import {
  ArrowPathIcon,
  BuildingLibraryIcon,
  CreditCardIcon,
} from "@heroicons/react/24/outline";
import { Badge } from "../catalyst/badge";
import LinkLogo from "../../images/link-logo.svg";
import BrandSetupStepButton, {
  BrandSetupStepButtonType,
} from "../setup/BrandSetupStepButton";

export default function PaymentsSettingsPage() {
  const { brandRecord } = useContext(UserContext);

  return (
    <>
      <KaleBrandsHelmet pageName="My Plan" />
      <div className="mx-auto max-w-7xl space-y-10 px-4 pt-4 sm:px-6 md:px-8">
        <div className="space-y-2">
          <Text>
            <Strong className="text-lg">Your cost breakdown</Strong>
          </Text>
          <div className="grid grid-cols-3 grid-rows-1 gap-6">
            <FormattedCost
              name="Platform fee"
              amount={brandRecord?.platformFee}
            />
            <FormattedCost
              name="Monthly content budget"
              amount={brandRecord?.contentBudgetMax}
            />
            <FormattedCost
              name="Remaining budget"
              amount={brandRecord?.contentBudget}
            />
          </div>
        </div>
        <BrandPaymentMethods brand={brandRecord} />
      </div>
    </>
  );
}

function FormattedCost({ name, amount }: { name: string; amount: number }) {
  return (
    <div className="space-y-2">
      <div className="mb-4 h-px bg-gray-200"></div>
      <Text>
        <Strong>{name}</Strong>
      </Text>
      <p className="text-xl font-bold">${prettyDecimal(amount)}</p>
    </div>
  );
}

function FormattedPaymentMethod({
  paymentMethod,
}: {
  paymentMethod: StripePaymentMethod;
}) {
  return (
    <div className="flex justify-between pt-2">
      <div className="flex items-center space-x-6">
        <div className="flex items-center space-x-2">
          <PaymentMethodIcon type={paymentMethod.type} />
          <Text>
            <Strong className="capitalize">{paymentMethod.name}</Strong>
          </Text>
          {paymentMethod.type === "card" && (
            <>
              <Text>
                <Strong>••••</Strong>
              </Text>
              <Text>
                <Strong>{paymentMethod.lastFourDigits}</Strong>
              </Text>
            </>
          )}
        </div>
        {paymentMethod.isDefault && <Badge color="cyan">Default</Badge>}
      </div>

      {paymentMethod.type === "card" && (
        <div>
          <Text>
            Expires {paymentMethod.expirationMonth}/
            {paymentMethod.expirationYear}
          </Text>
        </div>
      )}
    </div>
  );
}

function PaymentMethodIcon({ type }: { type: string }) {
  switch (type) {
    case "card":
      return <CreditCardIcon className="h-5 w-5" />;
    case "us_bank_account":
      return <BuildingLibraryIcon className="h-5 w-5" />;
    case "link":
      return (
        <img className="h-8 w-8 rounded-full p-1" src={LinkLogo} alt="Link" />
      );
    default:
      return null;
  }
}

export interface SavePaymentMethodsButtonData {
  buttonType: BrandSetupStepButtonType;
  shouldShowButton: boolean;
  onClick: () => void;
}

export function BrandPaymentMethods({
  brand,
  savePaymentMethodsButtonData,
}: {
  brand: Brand;
  savePaymentMethodsButtonData?: SavePaymentMethodsButtonData;
}) {
  const [loading, setLoading] = useState(false);
  const { refreshPaymentMethods } = useContext(UserContext);

  const [initiateCustomerPortal] = useLazyQuery(INITIATE_CUSTOMER_PORTAL);

  const handleAddPaymentMethodClick = async () => {
    const response = await initiateCustomerPortal({
      variables: { brandId: brand.id },
      fetchPolicy: "no-cache",
    });
    const url = response?.data?.initiateCustomerPortal?.url;

    window.open(url);
  };

  const handleRefreshPaymentMethodsClick = () => {
    setLoading(true);
    refreshPaymentMethods(brand.id);
    setLoading(false);
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <Text>
          <Strong className="text-lg">Your payment methods</Strong>
        </Text>
        <div className="space-x-2">
          <Button
            color={"white"}
            className="h-8 hover:cursor-pointer"
            onClick={handleRefreshPaymentMethodsClick}
          >
            <div>
              <ArrowPathIcon className="h-4 w-4" />
            </div>
          </Button>
          <Button
            color={"white"}
            className="h-8 hover:cursor-pointer"
            onClick={handleAddPaymentMethodClick}
          >
            <div>
              <PlusIcon className="h-4 w-4" />
            </div>
          </Button>
        </div>
      </div>
      <div className="mb-4 h-px bg-gray-200"></div>
      {loading ? (
        <div className="flex justify-center">
          <Spinner size="h-6 w-6" />
        </div>
      ) : (
        <div className="space-y-2 divide-y divide-gray-100">
          {brand?.paymentMethods?.length > 0 ? (
            brand.paymentMethods.map((paymentMethod) => (
              <FormattedPaymentMethod paymentMethod={paymentMethod} />
            ))
          ) : (
            <div className="rounded-md border border-dashed border-gray-300 pb-10 pt-10">
              <div className="text-center text-sm text-gray-400">
                No payment methods
              </div>
            </div>
          )}
        </div>
      )}
      {savePaymentMethodsButtonData?.shouldShowButton == true && (
        <div className="flex justify-end">
          <BrandSetupStepButton
            buttonType={savePaymentMethodsButtonData.buttonType}
            onClick={savePaymentMethodsButtonData.onClick}
            disabled={false}
          />
        </div>
      )}
    </div>
  );
}

export function isBrandPaymentMethodsComplete(brand: Brand): boolean {
  return brand.paymentMethods?.length > 0;
}

import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import PendingChallengesTable from "./PendingChallengesTable";

export default function PendingChallenges() {
  const { brandId, brandRecord } = useContext(UserContext);

  return (
    <div>
      <div className="mx-6 flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Challenges</h1>
      </div>
      <div className="mx-6">
        <PendingChallengesTable key={brandId} brandId={brandId} />
      </div>
    </div>
  );
}

import { useMutation, useQuery } from "@apollo/client";
import {
  BuildingStorefrontIcon,
  ChevronDownIcon,
  PhotoIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Brand, BrandCategory, Connection, Spinner } from "@kalecard/common";
import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { PARTIAL_UPDATE_BRAND } from "../../graphql/mutations";
import { upload } from "../../utils/upload";
import { Description, Field, Label } from "../catalyst/fieldset";
import { Input } from "../catalyst/input";
import { Radio, RadioField, RadioGroup } from "../catalyst/radio";
import { Strong, Text } from "../catalyst/text";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../catalyst/dropdown";
import { Badge } from "../catalyst/badge";
import { BRAND_CATEGORIES } from "../../graphql/queries";
import BrandSetupStepButton, {
  BrandSetupStepButtonType,
} from "../setup/BrandSetupStepButton";
import { UserContext } from "../../providers/UserProvider";

interface BrandInput {
  name: string;
  socialHandle?: string;
  tiktokSocialHandle?: string;
  logoFiles?: File[];
  coverFiles?: File[];
  recurringChargesEnabled?: boolean;
  newRequiredHashtag?: string;
  storeLocatorUrl?: string;
}

function isValidLink(link) {
  const regex = /^https:\/\/.*/;
  return !link || regex.test(link);
}

interface BrandCategoriesData {
  brandCategories: Connection<BrandCategory>;
}

export default function UpdateBrandDetailsForm({
  buttonType,
  onSave,
  showRequiredIndicator,
  disableSaveOnIncomplete,
}: {
  buttonType: BrandSetupStepButtonType;
  onSave: () => void;
  showRequiredIndicator: boolean;
  disableSaveOnIncomplete: boolean;
}) {
  const { brandRecord } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [logoSrc, setLogoSrc] = useState<string>(brandRecord?.logoUrl);
  const [coverSrc, setCoverSrc] = useState<string>(brandRecord?.coverUrl);
  const [successMessage, setSuccessMessage] = useState<string>(null);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [selectedCategories, setSelectedCategories] = useState<Set<string>>(
    new Set()
  );
  const [logoError, setLogoError] = useState(null);
  const [coverPhotoError, setCoverPhotoError] = useState(null);
  const [isGeoFenced, setIsGeoFenced] = useState(
    brandRecord?.isGeoFenced ?? false
  );

  const [brandCategories, setBrandCategories] = useState<BrandCategory[]>(
    brandRecord?.brandCategories ?? []
  );

  const addBrandCategory = (brandCategory: BrandCategory) => {
    console.log(brandCategory);
    const existingCategory = brandCategories.find(
      (category) => category.id === brandCategory.id
    );

    if (existingCategory == null) {
      setBrandCategories([...brandCategories, brandCategory]);
    }
  };

  const removeBrandCategory = (brandCategory: BrandCategory) => {
    const updatedCategories = brandCategories.filter(
      (category) => category.id !== brandCategory.id
    );
    setBrandCategories(updatedCategories);
  };

  const { data: brandCategoriesData, loading: brandCategoriesLoading } =
    useQuery<BrandCategoriesData>(BRAND_CATEGORIES);

  const { getValues, register, handleSubmit, watch, reset } =
    useForm<BrandInput>({
      defaultValues: {
        ...brandRecord,
      },
    });
  const [updateBrand] = useMutation(PARTIAL_UPDATE_BRAND);

  const computeIsDataComplete = () => {
    return (
      !!getValues("socialHandle") &&
      !!getValues("tiktokSocialHandle") &&
      brandCategories.length > 0 &&
      !!logoSrc &&
      !!coverSrc &&
      isGeoFenced != null &&
      (isGeoFenced == false || !!getValues("storeLocatorUrl"))
    );
  };
  const [isDataComplete, setIsDataComplete] = useState(computeIsDataComplete());

  const onSubmit: SubmitHandler<BrandInput> = async (data: BrandInput) => {
    if (data.storeLocatorUrl && !isValidLink(data.storeLocatorUrl)) {
      setErrorMessage(
        "Invalid URL format. Please enter a store locator URL that starts with 'https://'"
      );
      return;
    }

    setIsLoading(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    var uploadLogoResult = null;
    if (data.logoFiles.length > 0) {
      uploadLogoResult = await upload(
        data.logoFiles[0],
        "BRAND_LOGO",
        {
          data: [{ key: "brandId", value: brandRecord.id }],
        },
        brandRecord.id
      );
      console.log(uploadLogoResult);
    }

    var uploadCoverResult = null;
    if (data.coverFiles.length > 0) {
      uploadCoverResult = await upload(
        data.coverFiles[0],
        "BRAND_COVER",
        {
          data: [{ key: "brandId", value: brandRecord.id }],
        },
        brandRecord.id
      );
      console.log(uploadCoverResult);
    }

    const variables = {
      brandId: brandRecord.id,
      socialHandle: data.socialHandle,
      tiktokSocialHandle: data.tiktokSocialHandle,
      brandCategories: brandCategories.map((category) => category.id),
      logoBucketPath: uploadLogoResult?.bucketPath,
      coverBucketPath: uploadCoverResult?.bucketPath,
      storeLocatorUrl: data.storeLocatorUrl,
      isGeoFenced: isGeoFenced,
    };

    try {
      const result = await updateBrand({
        variables: variables,
      });
      setSuccessMessage("Congrats, your changes have been applied!");
      console.log(result);
      onSave();
    } catch (err) {
      console.error(err);
      setErrorMessage("Failed to apply your changes, try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "logoFiles") {
        if (
          FileReader && value.logoFiles.length > 0
            ? value.logoFiles[0].name
            : null
        ) {
          var fr = new FileReader();
          fr.onload = function () {
            const img = new Image();
            img.src = String(fr.result);
            img.onload = () => {
              console.log(img);
              if (img.width < 512 || img.height < 512) {
                setLogoError(
                  "Oops! Your image resolution is too low, please use a higher resolution. Minimum resolution is 512x512 px."
                );
                return;
              } else if (isImageRatioCorrect(img.width, img.height) !== true) {
                setLogoError(
                  "Oops! Your image isn't the right size. It must have a 1:1 ratio."
                );
                return;
              } else {
                setLogoError(null);
                setLogoSrc(String(fr.result));
              }
            };
          };
          fr.readAsDataURL(value.logoFiles[0]);
        }
      } else if (name === "coverFiles") {
        if (
          FileReader && value.coverFiles.length > 0
            ? value.coverFiles[0].name
            : null
        ) {
          var fr = new FileReader();
          fr.onload = function () {
            const img = new Image();
            img.src = String(fr.result);
            img.onload = () => {
              if (img.width < 512 || img.height < 512) {
                setLogoError(
                  "Oops! Your image resolution is too low, please use a higher resolution."
                );
                return;
              } else if (
                isImageRatioCorrect(img.width, img.height, 16, 9) !== true
              ) {
                setCoverPhotoError(
                  "Oops! Your image isn't the right size. It must have a 16:9 ratio."
                );
                return;
              } else {
                setCoverPhotoError(null);
                setCoverSrc(String(fr.result));
              }
            };
          };
          fr.readAsDataURL(value.coverFiles[0]);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setIsDataComplete(computeIsDataComplete());
  }, [isGeoFenced, brandCategories, logoSrc, coverSrc]);

  useEffect(() => {
    console.log(brandRecord);
    reset(brandRecord);
    setLogoSrc(brandRecord?.logoUrl);
    setCoverSrc(brandRecord?.coverUrl);
    setBrandCategories(brandRecord?.brandCategories ?? []);
    setIsGeoFenced(brandRecord?.isGeoFenced ?? false);
  }, [brandRecord]);

  return (
    <form className="w-full space-y-10" onSubmit={handleSubmit(onSubmit)}>
      <Field className="grid grid-cols-2">
        <FormattedDetailLabel
          name="Instagram Handle"
          required={showRequiredIndicator}
        />
        <div className="flex w-full items-center rounded-lg border border-gray-200 bg-gray-100">
          <p className="px-2 text-sm text-gray-500">instagram.com/</p>
          <Input
            name="socialHandle"
            {...register("socialHandle", { required: true })}
            onKeyUp={() => setIsDataComplete(computeIsDataComplete())}
          />
        </div>
      </Field>
      <Field className="grid grid-cols-2">
        <FormattedDetailLabel
          name="TikTok Handle"
          required={showRequiredIndicator}
        />
        <div className="flex w-full items-center rounded-lg border border-gray-200 bg-gray-100">
          <p className="px-2 text-sm text-gray-500">tiktok.com/@</p>
          <Input
            name="tiktokSocialHandle"
            {...register("tiktokSocialHandle", { required: true })}
            onKeyUp={() => setIsDataComplete(computeIsDataComplete())}
          />
        </div>
      </Field>

      <Field className="grid grid-cols-2">
        <div>
          <FormattedDetailLabel name="Logo" required={showRequiredIndicator} />
          <Description>Image must a square with a 1:1 ratio.</Description>
        </div>
        <div className="flex items-center space-x-2">
          {logoSrc ? (
            <img
              className="h-12 w-12 rounded-full border border-kale-green-500"
              src={logoSrc}
              key={logoSrc}
            />
          ) : (
            <BuildingStorefrontIcon className="inline-block h-12 w-12 rounded-full" />
          )}
          <label className="ml-5 cursor-pointer rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span className="text-base leading-normal">Change</span>
            <input
              className="hidden"
              {...register("logoFiles")}
              accept="image/png,image/jpeg"
              type="file"
              name="logoFiles"
            />
          </label>
        </div>

        {logoError && (
          <div className="pt-2 text-sm font-medium text-red-500">
            {logoError}
          </div>
        )}
      </Field>
      <Field className="grid grid-cols-2">
        <div>
          <FormattedDetailLabel
            name="Cover Photo"
            required={showRequiredIndicator}
          />
          <Description>Image must have a 16:9 ratio.</Description>
        </div>
        <div className="flex items-center space-x-2 pt-3">
          {coverSrc ? (
            <img
              className="h-36 w-64 border border-kale-green-500 object-cover"
              src={coverSrc}
              key={coverSrc}
            />
          ) : (
            <PhotoIcon className="inline-block h-12 w-12" />
          )}
          <label className="ml-5 cursor-pointer rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span className="text-base leading-normal">Change</span>
            <input
              className="hidden"
              {...register("coverFiles")}
              accept="image/png,image/jpeg"
              type="file"
              name="coverFiles"
            />
          </label>
        </div>
        {coverPhotoError && (
          <div className="pt-2 text-sm font-medium text-red-500">
            {coverPhotoError}
          </div>
        )}
      </Field>

      <Field className="grid grid-cols-2">
        <FormattedDetailLabel
          name="Store Locator URL"
          required={showRequiredIndicator && isGeoFenced}
        />
        <div className="w-full">
          <Input
            name="storeLocatorUrl"
            {...register("storeLocatorUrl", {
              required: isGeoFenced === true,
            })}
            onKeyUp={() => setIsDataComplete(computeIsDataComplete())}
          />
        </div>
      </Field>

      <RadioGroup
        key={"isGeoFenced" + isGeoFenced}
        name={"isGeoFenced"}
        defaultValue={isGeoFenced ? "no" : "yes"}
        onChange={(val) => setIsGeoFenced(val == "no")}
        className="grid grid-cols-2 items-center"
      >
        <FormattedDetailLabel
          name="Are you sold nationwide?"
          required={showRequiredIndicator}
        />
        <div className="cols-span-1">
          <RadioField>
            <Label>Yes</Label>
            <Radio value={"yes"} color="emerald" />
          </RadioField>
          <RadioField>
            <Label>No</Label>
            <Radio value={"no"} color="zinc" />
          </RadioField>
        </div>
      </RadioGroup>

      <div className="grid grid-cols-2">
        <Field className="w-3/4 space-y-3">
          <FormattedDetailLabel
            name="Brand Categories"
            required={showRequiredIndicator}
          />
          <Description>
            Select up to three. This determines your brand's placement in the
            Kale app.
          </Description>
          {brandCategoriesLoading ? (
            <div className="flex flex-wrap content-center justify-center pr-2">
              <Spinner size="h-4 w-4" />
            </div>
          ) : (
            <Dropdown>
              <DropdownButton outline>
                Select a category
                <ChevronDownIcon />
              </DropdownButton>
              <DropdownMenu>
                {brandCategoriesData?.brandCategories?.edges.map((edge) => (
                  <DropdownItem
                    key={edge.node.id}
                    onClick={() => addBrandCategory(edge.node)}
                  >
                    {edge.node.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          )}
        </Field>
        <div className="flex h-fit flex-wrap gap-2">
          {brandCategories.map((brandCategory) => (
            <Badge
              key={brandCategory.id}
              color="lime"
              className="h-fit space-x-1"
            >
              <Text>
                <Strong>{brandCategory.name}</Strong>
              </Text>
              <XMarkIcon
                className="h-4 w-4 text-gray-500"
                onClick={() => removeBrandCategory(brandCategory)}
              />
            </Badge>
          ))}
        </div>
      </div>

      <div className="space-y-1">
        <div className="flex flex-row items-center justify-end space-x-4">
          {successMessage && (
            <p className="text-sm font-medium text-green-500">
              {successMessage}
            </p>
          )}
          {errorMessage && (
            <p className="text-sm font-medium text-red-500">{errorMessage}</p>
          )}
          {isLoading && (
            <div className="flex flex-wrap content-center justify-center pr-2">
              <Spinner size="h-6 w-6" />
            </div>
          )}
          <BrandSetupStepButton
            buttonType={buttonType}
            onClick={handleSubmit(onSubmit)}
            disabled={disableSaveOnIncomplete && !isDataComplete}
          />
        </div>
        {showRequiredIndicator && (
          <div className="flex items-center justify-end space-x-1">
            <p className="text-red-500">*</p>
            <p className="text-xs italic">Required field</p>
          </div>
        )}
      </div>
    </form>
  );
}

function isImageRatioCorrect(
  width,
  height,
  desiredRatioWidth = 1,
  desiredRatioHeight = 1,
  tolerance = 0.05
) {
  const ratio = width / height;
  const desiredRatio = desiredRatioWidth / desiredRatioHeight;
  return Math.abs(ratio - desiredRatio) <= tolerance;
}

export function isBrandDetailsStepComplete(brand: Brand) {
  return (
    !!brand.socialHandle &&
    !!brand.tiktokSocialHandle &&
    brand.brandCategories.length > 0 &&
    !!brand.logoUrl &&
    !!brand.coverUrl &&
    brand.isGeoFenced != null &&
    (brand.isGeoFenced == false || !!brand.storeLocatorUrl)
  );
}

function FormattedDetailLabel({
  name,
  required,
}: {
  name: string;
  required: boolean;
}) {
  return (
    <div className="flex space-x-1">
      <Label>
        <Strong>{name}</Strong>
      </Label>
      {required && <p className="text-red-500">*</p>}
    </div>
  );
}

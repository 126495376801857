import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import KaleBrandsHelmet from "../components/utils/KaleBrandsHelmet";
import BrandReportDashboard from "../components/BrandReportDashboard";

export default function ReportsPage() {
  const { brandRecord } = useContext(UserContext);

  return (
    <>
      <KaleBrandsHelmet pageName="Reports" />
      <div className="flex flex-col justify-between space-y-4 pb-2">
        {/* Header */}
        <div className="px-4 font-semibold text-gray-900 sm:px-6 md:px-8">
          <h1 className="text-2xl">Reports</h1>
        </div>

        {brandRecord && <BrandReportDashboard brandId={brandRecord.id} />}
      </div>
    </>
  );
}

import { useMutation } from "@apollo/client";
import { CheckIcon } from "@heroicons/react/24/outline";
import { Spinner } from "@kalecard/common";
import { truncate } from "fs/promises";
import { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { BLOCK_HANDLES } from "../../graphql/mutations";
import { UserContext } from "../../providers/UserProvider";

export type BlockUsernamesFormInputs = {
  usernames: string;
  externalPlatform: string;
};

interface BlockUsernamesFormProps {
  onSubmit?: () => void;
  refetchBlockedUsers?: () => void;
}

export default function BlockUsernames(
  props: BlockUsernamesFormProps
) {
  const { brandId } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { reset, register, handleSubmit } =
    useForm<BlockUsernamesFormInputs>();
  const [blockHandles] = useMutation(BLOCK_HANDLES);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const submitBlockUsernamesForm = (
    data: BlockUsernamesFormInputs
  ) => {
    const specialChars = /[`!@#$%^&*()+\-=\[\]{};':"\\|<>\/?~]/;
    if (specialChars.test(data.usernames) == true) {
      setError("Please remove all special characters, except underscores and periods that are part of a username.");
    } else {
      setError(null);
      submitForm(data);
      setSubmitSuccess(true);
    }
  };

  const submitForm: SubmitHandler<BlockUsernamesFormInputs> = async (
    data
  ) => {
    setIsLoading(true);
    console.log(data);
    const variables = {
      brandId: brandId,
      usernames: data.usernames.split(","),
      externalPlatform: data.externalPlatform,
    };
    try {
      const result = await blockHandles({ variables });
      console.log(result);
      if (result.data.blockHandles == true) {
        setError(null);
        reset();
        props.refetchBlockedUsers();
      } else {
        // Set an error
        console.error("Error blocking usernames.");
        setError(
          "Something went wrong, try refreshing this page or try again later."
        );
      }
    } catch (err) {
      console.error(err);
      setError("Something went wrong, please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form>
        <p className="text-xs text-gray-500">
          To block more than one username at a time, enter a comma-separated list of usernames.
        </p>
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-1 grid grid-rows-2">
            <div className="flex items-end">
              <label
                htmlFor="usernames"
                className="block text-sm font-medium text-gray-700"
              >
                Usernames
              </label>
            </div>
            <div>
              <input
                placeholder={"e.g. lizzthecreator, juicy_lucy, healthyvegan"}
                id="usernames"
                type="text"
                {...register("usernames", { required: true })}
                className="block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm w-full"
              />
            </div>
          </div>
          <div className="col-span-1 grid grid-rows-2">
            <div className="flex items-end">
              <label
                htmlFor="externalPlatform"
                className="block text-sm font-medium text-gray-700"
              >
                Platform
              </label>
            </div>
            <div>
              <select
                name="externalPlatform"
                {...register("externalPlatform", { required: true })}
                id="externalPlatform"
                className="block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm w-full"
              >
                <option value="TIKTOK">TikTok</option>
                <option value="INSTAGRAM">Instagram</option>
              </select>
            </div>
          </div>
          <div className="col-span-1 grid grid-rows-2">
            <div className="flex items-center row-start-2 row-span-1 content-center">
              <button
                type="button"
                onClick={handleSubmit(submitBlockUsernamesForm)}
                disabled={isLoading}
                className="max-w-20 place-self-center rounded-md border border-transparent bg-kale-mint-500 py-2 px-4 text-sm font-medium text-gray-900 shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-kale-green-500 focus:ring-offset-2"
              >
                Block
              </button>
              {isLoading && (
                <div className="pl-2">
                  <Spinner size="h-6 w-6" />
                </div>)}
              {!isLoading && submitSuccess && (
                <div className="pl-2">
                  <CheckIcon className="h-6 w-6" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-2">
          <p className="font-bold text-sm text-red-500">{error}</p>
        </div>
      </form>
    </>
  );
}

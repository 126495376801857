import { useRef } from "react";
import { classNames } from "../utils/style";
import { useIsVisible } from "react-is-visible";

interface MediaComponentProps {
  url: string;
  mediaType: string;
  shouldChangeColor?: boolean;
  showControls?: boolean;
  width?: number;
  height?: number;
}

export default function MediaComponent({
  url,
  mediaType,
  shouldChangeColor,
  showControls = true,
  width = 266,
  height = 473,
}: MediaComponentProps) {
  const mediaRef = useRef(null);
  const isVisible = useIsVisible(mediaRef);
  return (
    <div className="flex justify-center" ref={mediaRef}>
      <div className="flex w-full items-center justify-center rounded-lg">
        <div
          className={classNames(
            "h-[" + height + "px] w-[" + width + "px] relative rounded-2xl",
            shouldChangeColor === true
              ? "bg-kale-orange-200"
              : "bg-kale-green-200"
          )}
        >
          {mediaType === "IMAGE" && (
            <img
              key={url}
              src={url}
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
              onError={(e) =>
                ((e.target as HTMLImageElement).style.display = "none")
              }
              className="flex:1 h-full w-full rounded-2xl object-cover p-2"
            ></img>
          )}
          {mediaType === "VIDEO" && isVisible && (
            <video
              controls={showControls === true ? true : false}
              key={url}
              autoPlay={false}
              loop={true}
              muted={true}
              playsInline={true}
              src={url}
              controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
              disablePictureInPicture={true}
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
              onError={(e) =>
                ((e.target as HTMLVideoElement).style.display = "none")
              }
              className="flex:1 h-full w-full rounded-2xl object-cover p-2"
            ></video>
          )}
        </div>
      </div>
    </div>
  );
}

import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import ReferralLinkGenerator from "../components/creators/ReferralLinkGenerator";
import KaleBrandsHelmet from "../components/utils/KaleBrandsHelmet";
import InfluencerBlockList from "../components/creators/InfluencerBlockList";
import CreatorStats from "../components/creators/CreatorStats";
import { Route, Switch } from "react-router";
import { NavLink } from "react-router-dom";

const tabs = [
  { name: "Landing Page Links", href: "/creators/links", current: true },
  { name: "Creator Stats", href: "/creators/stats", current: false },
  {
    name: "Influencer Block List",
    href: "/creators/blocklist",
    current: false,
  },
];

const CreatorsPage = () => {
  return (
    <>
      <KaleBrandsHelmet pageName="Creators" />
      <div className="max-w-7xl px-4 pb-2 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Creators</h1>
      </div>
      <div className="border-b border-gray-200 px-4 pt-2 sm:px-6 md:px-8">
        <div className="block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href}
                className={(isActive) =>
                  (isActive
                    ? "border-b-2 border-black text-black "
                    : "text-gray-500 hover:border-b-2 hover:border-gray-500 hover:text-gray-600") +
                  "whitespace-nowrap px-1 pb-4 text-sm font-medium"
                }
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      <div className="mx-8">
        <Switch>
          <Route path="/creators/links">
            <ReferralLinkGenerator />
          </Route>
          <Route path="/creators/stats">
            <CreatorStats />
          </Route>
          <Route path="/creators/blocklist">
            <InfluencerBlockList />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default CreatorsPage;

import gql from "graphql-tag";

export const AUTHENTICATE_BRAND_EMAIL = gql`
  mutation AuthenticateBrandEmail($brandEmail: String!) {
    authenticateBrandEmail(brandEmail: $brandEmail)
  }
`;

export const BRAND_USER_SIGN_UP = gql`
  mutation BrandUserSignUp(
    $id: ID
    $name: String!
    $email: String
    $companyTitle: String
  ) {
    brandUserSignUp(
      id: $id
      name: $name
      email: $email
      companyTitle: $companyTitle
    )
  }
`;

export const REQUEST_WHITELISTING_ACCESS = gql`
  mutation RequestWhitelistingAccess($brandId: ID!) {
    requestWhitelistingAccess(brandId: $brandId)
  }
`;

export const CONTENT_WHITELIST_REQUEST = gql`
  mutation ContentWhitelistRequest(
    $postId: ID!
    $childId: ID
    $brandId: ID!
    $budget: Float!
    $postWhitelistRequestId: ID
  ) {
    contentWhitelistRequest(
      postId: $postId
      childId: $childId
      brandId: $brandId
      budget: $budget
      postWhitelistRequestId: $postWhitelistRequestId
    )
  }
`;

export const CONTENT_WHITELIST_REJECT = gql`
  mutation ContentWhitelistReject(
    $postWhitelistRequestId: ID!
    $postScoreIds: [ID]!
    $brandFeedback: String
  ) {
    contentWhitelistReject(
      postWhitelistRequestId: $postWhitelistRequestId
      postScoreIds: $postScoreIds
      brandFeedback: $brandFeedback
    )
  }
`;
export const COMMENT_ON_MEDIA = gql`
  mutation CommentOnMedia(
    $externalMediaId: ID!
    $brandId: ID!
    $facebookAccountId: ID!
    $message: String!
  ) {
    commentOnMedia(
      externalMediaId: $externalMediaId
      brandId: $brandId
      facebookAccountId: $facebookAccountId
      message: $message
    )
  }
`;

export const REPLY_TO_MENTIONED_MEDIA = gql`
  mutation ReplyToMentionedMedia(
    $externalMediaId: ID!
    $brandId: ID!
    $facebookAccountId: ID!
    $message: String!
  ) {
    replyToMentionedMedia(
      externalMediaId: $externalMediaId
      brandId: $brandId
      facebookAccountId: $facebookAccountId
      message: $message
    )
  }
`;

export const UPDATE_BRAND_FACEBOOK_ACCESS_TOKEN = gql`
  mutation UpdateBrandFacebookAccessToken(
    $accessToken: String!
    $scopes: String!
    $domain: String!
    $brandId: ID!
  ) {
    updateBrandFacebookAccessToken(
      accessToken: $accessToken
      scopes: $scopes
      domain: $domain
      brandId: $brandId
    )
  }
`;

export const CHOOSE_BRAND_FACEBOOK_ACCOUNTS = gql`
  mutation ChooseBrandFacebookAccounts($facebookIds: [ID], $brandId: ID!) {
    chooseBrandFacebookAccounts(facebookIds: $facebookIds, brandId: $brandId) {
      id
      facebookId
    }
  }
`;

export const SEND_BRAND_INSTAGRAM_MESSAGE = gql`
  mutation SendBrandInstagramMessage(
    $instagramId: String!
    $recipientId: String!
    $text: String!
    $brandId: ID!
  ) {
    sendBrandInstagramMessage(
      instagramId: $instagramId
      recipientId: $recipientId
      text: $text
      brandId: $brandId
    )
  }
`;

export const BLOCK_HANDLES = gql`
  mutation BlockHandles(
    $brandId: ID!
    $usernames: [String!]
    $externalPlatform: String!
  ) {
    blockHandles(
      brandId: $brandId
      usernames: $usernames
      externalPlatform: $externalPlatform
    )
  }
`;

export const GET_UPLOAD_SIGNED_URL = gql`
  mutation GetUploadSignedUrl(
    $mimeType: String!
    $uploadType: String!
    $metadata: UploadRequestMetadata
    $brandId: ID
  ) {
    getUploadSignedUrl(
      mimeType: $mimeType
      uploadType: $uploadType
      metadata: $metadata
      brandId: $brandId
    ) {
      mimeType
      uploadType
      bucketPath
      signedUrl
    }
  }
`;

export const PARTIAL_UPDATE_BRAND = gql`
  mutation PartialUpdateBrand(
    $brandId: ID!
    $socialHandle: String
    $tiktokSocialHandle: String
    $brandAdmins: [String]
    $brandCategories: [String]
    $logoBucketPath: String
    $coverBucketPath: String
    $storeLocatorUrl: String
    $isGeoFenced: Boolean
  ) {
    partialUpdateBrand(
      brandId: $brandId
      socialHandle: $socialHandle
      tiktokSocialHandle: $tiktokSocialHandle
      brandAdmins: $brandAdmins
      logoBucketPath: $logoBucketPath
      brandCategories: $brandCategories
      coverBucketPath: $coverBucketPath
      storeLocatorUrl: $storeLocatorUrl
      isGeoFenced: $isGeoFenced
    ) {
      id
      socialHandle
      tiktokSocialHandle
      logoUrl
      coverUrl
      storeLocatorUrl
      isGeoFenced
      brandCategories {
        id
        name
      }
    }
  }
`;

export const UPDATE_BRAND_LANDING_PAGE = gql`
  mutation UpdateBrandLandingPage(
    $brandId: ID!
    $videos: [BrandLandingPageVideoInput]
  ) {
    updateBrandLandingPage(brandId: $brandId, videos: $videos) {
      brand {
        id
      }
    }
  }
`;

export const UPDATE_BRAND_ADMINS = gql`
  mutation UpdateBrandAdmins($brandId: ID!, $brandAdminEmails: [String]) {
    updateBrandAdmins(brandId: $brandId, brandAdminEmails: $brandAdminEmails)
  }
`;

export const SUGGEST_CHALLENGE = gql`
  mutation SuggestChallenge(
    $brandId: ID!
    $categoryId: ID!
    $note: String
    $exampleUrl: String
    $startDate: String
    $endDate: String
  ) {
    suggestChallenge(
      brandId: $brandId
      description: ""
      categoryId: $categoryId
      note: $note
      exampleUrl: $exampleUrl
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      description
      createdAt
      state
      brand {
        id
        name
        logoUrl
      }
      exampleUrl
      tagRequirements
      startDate
      endDate
    }
  }
`;

export const UPDATE_SUGGESTED_CHALLENGE = gql`
  mutation UpdateSuggestedChallenge(
    $brandId: ID!
    $challengeId: ID!
    $categoryId: ID
    $exampleUrl: String
    $state: String
    $startDate: String
    $endDate: String
    $note: String
  ) {
    updateSuggestedChallenge(
      brandId: $brandId
      challengeId: $challengeId
      categoryId: $categoryId
      exampleUrl: $exampleUrl
      state: $state
      startDate: $startDate
      endDate: $endDate
      note: $note
    ) {
      id
      description
      createdAt
      state
      brand {
        id
        name
        logoUrl
      }
      exampleUrl
      tagRequirements
      startDate
      endDate
    }
  }
`;

export const NEW_CHALLENGE_FEEDBACK = gql`
  mutation NewChallengeFeedback(
    $brandId: ID!
    $challengeId: ID!
    $feedback: String!
  ) {
    newChallengeFeedback(
      brandId: $brandId
      challengeId: $challengeId
      feedback: $feedback
    )
  }
`;

export const REVIEW_CHALLENGE = gql`
  mutation ReviewChallenge(
    $brandId: ID!
    $challengeId: ID!
    $comments: String
    $isApproved: Boolean!
  ) {
    reviewChallenge(
      brandId: $brandId
      challengeId: $challengeId
      comments: $comments
      isApproved: $isApproved
    ) {
      id
      description
      createdAt
      state
      brand {
        id
        name
        logoUrl
      }
      exampleUrl
      tagRequirements
      startDate
      endDate
    }
  }
`;

export const COMPLETE_BRAND_USER_DATA = gql`
  mutation CompleteBrandUserData($companyTitle: String) {
    completeBrandUserData(companyTitle: $companyTitle)
  }
`;

export const UPDATE_POST_COLLECTION_MEMBERSHIP = gql`
  mutation UpdatePostCollectionMembership(
    $brandId: ID!
    $postId: ID!
    $includedCollections: [ID]!
    $excludedCollections: [ID]!
  ) {
    updatePostCollectionMembership(
      brandId: $brandId
      postId: $postId
      includedCollections: $includedCollections
      excludedCollections: $excludedCollections
    ) {
      id
      collections(brandId: $brandId) {
        id
        title
      }
    }
  }
`;

export const DELETE_POST_COLLECTION = gql`
  mutation DeletePostCollection($brandId: ID!, $collectionId: ID!) {
    deletePostCollection(brandId: $brandId, collectionId: $collectionId)
  }
`;

export const CREATE_POST_COLLECTION = gql`
  mutation CreatePostCollection($brandId: ID!, $title: String!) {
    createPostCollection(brandId: $brandId, title: $title) {
      id
      title
    }
  }
`;

export const UPDATE_POST_COLLECTION = gql`
  mutation UpdatePostCollection(
    $brandId: ID!
    $collectionId: ID!
    $title: String!
  ) {
    updatePostCollection(
      brandId: $brandId
      collectionId: $collectionId
      title: $title
    ) {
      id
      title
    }
  }
`;

export const COMPLETE_BONSAI_TASK = gql`
  mutation CompleteBonsaiTask(
    $queueName: String!
    $taskId: Int!
    $outcome: JSON!
  ) {
    completeBonsaiTask(
      queueName: $queueName
      taskId: $taskId
      outcome: $outcome
    ) {
      taskId
    }
  }
`;

export const UPDATE_BRAND_PO_NUMBERS = gql`
  mutation UpdateBrandPONumbers(
    $brandId: ID!
    $platformFeePoNumber: String
    $contentBudgetPoNumber: String
  ) {
    updateBrandPONumbers(
      brandId: $brandId
      platformFeePoNumber: $platformFeePoNumber
      contentBudgetPoNumber: $contentBudgetPoNumber
    )
  }
`;

export const ADD_BRAND_INFLUENCER_POSTS = gql`
  mutation AddBrandInfluencerPosts($brandId: ID!, $postUrls: [String]!) {
    addBrandInfluencerPosts(brandId: $brandId, postUrls: $postUrls)
  }
`;

import { useLazyQuery } from "@apollo/client";
import { Spinner } from "@kalecard/common";
import { useContext, useEffect, useState } from "react";
import {
  ChallengesListInterface,
  SIMPLE_CHALLENGES_LIST,
} from "../../graphql/queries";
import ChallengeRow from "./ChallengeRow";
import { ChallengesContext } from "../../providers/ChallengesProvider";
import { ChallengesActionType } from "../../reducers/ChallengesReducer";

export default function ChallengesTable({ brandId }: { brandId?: string }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const {
    state: { challenges, hasNextPage, after, shouldUpdateChallenges },
    dispatch: challengesDispatch,
  } = useContext(ChallengesContext);

  const [getChallengesList] = useLazyQuery<ChallengesListInterface>(
    SIMPLE_CHALLENGES_LIST
  );

  const getChallenges = async () => {
    setIsLoading(true);
    const result = await getChallengesList({
      variables: {
        after,
        brandId,
        onlyCompleted: false,
      },
      fetchPolicy: "network-only",
    });
    setIsLoading(false);
    return result.data;
  };

  const getMoreChallenges = async () => {
    setIsLoadingMore(true);
    await getChallenges().then((data) => {
      challengesDispatch({
        type: ChallengesActionType.UPDATE_CHALLENGES,
        payload: {
          challenges: data.challengesList.edges.map((edge) => edge.node),
          hasNextPage: data.challengesList.pageInfo.hasNextPage,
        },
      });
    });
    setIsLoadingMore(false);
  };

  const updateChallenges = () => {
    getChallenges().then((data) => {
      challengesDispatch({
        type: ChallengesActionType.SET_CHALLENGES,
        payload: {
          challenges: data.challengesList.edges.map((edge) => edge.node),
          hasNextPage: data.challengesList.pageInfo.hasNextPage,
        },
      });
    });
  };

  useEffect(() => {
    if (shouldUpdateChallenges === true) {
      updateChallenges();
    }
  }, [shouldUpdateChallenges]);

  return (
    <>
      <div className="flex-cols flex w-full">
        <div className="w-full">
          <div className="inline-block w-full py-2 align-middle">
            <div className="overflow-hidden">
              {challenges.length > 0 && (
                <table className="w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-4 pr-6 text-left text-sm font-semibold text-gray-900"
                      >
                        Prompt
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-center text-sm font-semibold text-gray-900"
                      >
                        Start Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-center text-sm font-semibold text-gray-900"
                      >
                        End Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-center text-sm font-semibold text-gray-900"
                      >
                        Stats
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-center text-sm font-semibold text-gray-900"
                      >
                        State
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-center text-sm font-semibold text-gray-900"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {challenges.map((challenge) => {
                      return (
                        <ChallengeRow
                          key={challenge.id}
                          challenge={challenge}
                        />
                      );
                    })}
                  </tbody>
                </table>
              )}
              {challenges.length == 0 && !isLoading && (
                <div className="whitespace-nowrap px-6 py-4 text-sm">
                  No challenges yet!
                </div>
              )}
            </div>
            {(isLoading || isLoadingMore) && (
              <div className="mt-2 flex w-full justify-center">
                <Spinner size="h-5 w-5" />
              </div>
            )}
            {hasNextPage && !isLoading && (
              <div className="mt-2 flex w-full justify-center">
                <button
                  type="button"
                  onClick={getMoreChallenges}
                  className="inline-flex items-center justify-center rounded-full border border-transparent bg-green-900 px-4 py-2 text-sm text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2 sm:w-auto"
                >
                  Load more
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export const renderChallengeState = (state: string) => {
  switch (state) {
    case "ACTIVE":
      return (
        <span className="inline-flex rounded-full bg-kale-mint-400 px-3 py-1.5 text-xs leading-5 text-black">
          Active
        </span>
      );
    case "INACTIVE":
      return (
        <span className="inline-flex rounded-full bg-zinc-100 px-3 py-1.5 text-xs leading-5 text-black">
          Inactive
        </span>
      );
    case "PENDING_REVIEW":
      return (
        <span className="inline-flex rounded-full bg-green-700 px-3 py-1.5 text-xs leading-5 text-white">
          In Review By Kale
        </span>
      );
    case "REJECTED":
      return (
        <span className="inline-flex rounded-full bg-green-900 px-3 py-1.5 text-xs leading-5 text-white">
          Rejected By Kale
        </span>
      );
    default:
      return null;
  }
};

export function formatMetric(metric: number): string {
  return Intl.NumberFormat("en", { notation: "compact" }).format(metric);
}

import { useContext } from "react";
import { ChallengesContext } from "../../providers/ChallengesProvider";
import PendingChallengeRow from "./PendingChallengeRow";

export default function PendingChallengesTable({
  brandId,
}: {
  brandId?: string;
}) {
  const {
    state: { pendingChallenges },
    dispatch: challengesDispatch,
  } = useContext(ChallengesContext);

  return (
    <>
      <div className="flex-cols flex w-full">
        <div className="w-full">
          <div className="inline-block w-full py-2 align-middle">
            <div className="overflow-hidden">
              {pendingChallenges.length > 0 && (
                <table className="w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-4 pr-6 text-left text-sm font-semibold text-gray-900"
                      >
                        Prompt
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-center text-sm font-semibold text-gray-900"
                      >
                        Created on
                      </th>
                      <th className="px-6 py-4"></th>
                      <th className="px-6 py-4"></th>
                      <th className="px-6 py-4"></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {pendingChallenges.map((challenge) => {
                      return (
                        <PendingChallengeRow
                          key={challenge.id}
                          challenge={challenge}
                        />
                      );
                    })}
                  </tbody>
                </table>
              )}
              {pendingChallenges.length == 0 && (
                <div className="whitespace-nowrap py-4 text-sm">
                  There are no new challenges waiting to be approved.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { Challenge } from "@kalecard/common";

export enum ChallengesActionType {
  SET_CHALLENGES,
  UPDATE_CHALLENGES,
  NEW_CHALLENGE,
  UPDATE_CHALLENGE,
  REVIEW_CHALLENGE,
}

export interface ChallengesState {
  challenges: Challenge[];
  hasNextPage?: boolean;
  after?: string;
  shouldUpdateChallenges?: boolean;
  pendingChallenges: Challenge[];
}

export interface ChallengesAction {
  type: ChallengesActionType;
  payload: any;
}

function updateChallengeIfApplicable(
  tempChallenge: Challenge,
  challenge: Challenge
): Challenge {
  if (tempChallenge.id === challenge.id) {
    return challenge.state === "DELETED" ? null : challenge;
  }
  return tempChallenge;
}

export function ChallengesReducer(
  state: ChallengesState,
  action: ChallengesAction
): ChallengesState {
  switch (action.type) {
    case ChallengesActionType.SET_CHALLENGES: {
      const { challenges, hasNextPage } = action.payload as ChallengesState;
      return {
        ...state,
        challenges: challenges,
        hasNextPage: hasNextPage,
        after: challenges.length.toString(),
        shouldUpdateChallenges: false,
      };
    }

    case ChallengesActionType.UPDATE_CHALLENGES: {
      const { challenges, hasNextPage } = action.payload as ChallengesState;

      const updatedChallenges = [...state.challenges, ...challenges];
      return {
        ...state,
        challenges: updatedChallenges,
        hasNextPage: hasNextPage,
        after: updatedChallenges.length.toString(),
        shouldUpdateChallenges: false,
      };
    }

    case ChallengesActionType.NEW_CHALLENGE: {
      const challenge = action.payload as Challenge;
      return {
        ...state,
        challenges: [challenge, ...state.challenges],
      };
    }

    case ChallengesActionType.UPDATE_CHALLENGE: {
      const challenge = action.payload as Challenge;

      const updatedChallenges = state.challenges
        .map((tempChallenge) => {
          return updateChallengeIfApplicable(tempChallenge, challenge);
        })
        .filter((tempChallenge) => tempChallenge);

      return {
        ...state,
        challenges: updatedChallenges,
      };
    }

    case ChallengesActionType.REVIEW_CHALLENGE: {
      const challenge = action.payload as Challenge;

      const updatedPendingChallenges = state.pendingChallenges.filter(
        (pendingChallenge) => {
          return pendingChallenge.id !== challenge.id;
        }
      );

      return {
        ...state,
        shouldUpdateChallenges: true,
        pendingChallenges: updatedPendingChallenges,
        after: null,
        hasNextPage: null,
      };
    }
  }
}

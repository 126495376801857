import { createContext, useContext, useEffect, useState } from "react";
import { Badges, BRAND_BADGES } from "../graphql/queries";
import { useQuery } from "@apollo/client";
import { UserContext } from "./UserProvider";

export const BadgesContext = createContext<{
  badges: Badges;
  setBadges: React.Dispatch<React.SetStateAction<Badges>>;
}>(null);

export const BadgesDispatchContext = createContext(null);

const BadgesProvider = ({ children }: any) => {
  const [badges, setBadges] = useState<Badges>(null);
  const { brandId } = useContext(UserContext);

  const { data } = useQuery(
    BRAND_BADGES,
    {
      variables: {
        brandId,
      },
      onCompleted: (data) => {
        setBadges(data.brandBadges);
      },
      fetchPolicy: "network-only",
    }
  );

  return (
    <BadgesContext.Provider
      value={{ badges, setBadges }}
    >
      {children}
    </BadgesContext.Provider>
  );
};

export default BadgesProvider;

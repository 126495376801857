import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import ChallengesTable from "./ChallengesTable";
import { useHistory } from "react-router";
import ChallengeSuggestButton from "./ChallengeSuggestButton";

export default function BrandChallenges() {
  const { brandId } = useContext(UserContext);
  const history = useHistory();

  return (
    <div>
      <div className="mx-6 flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Challenges</h1>
        <div>
          <ChallengeSuggestButton
            onClick={() => {
              history.push("/challengeSuggestions?openModal=true");
            }}
          />
        </div>
      </div>
      <div className="mx-6">
        <ChallengesTable key={brandId} brandId={brandId} />
      </div>
    </div>
  );
}

import { Fragment, useContext, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  ChevronDownIcon,
  ChevronRightIcon,
  ClipboardIcon,
  Cog8ToothIcon,
  CreditCardIcon,
  MegaphoneIcon,
  BookmarkIcon,
  PlusIcon,
  PhotoIcon,
  ShoppingBagIcon,
  StarIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link, NavLink } from "react-router-dom";
import { UserContext } from "../../providers/UserProvider";
import { BadgesContext } from "../../providers/BadgesProvider";
import { UserIcon } from "@heroicons/react/24/solid";
import { Brand, Modal } from "@kalecard/common";
import Badge from "../utils/Badge";
import { classNames } from "../../utils/style";
import { useQuery } from "@apollo/client";
import { POST_COLLECTIONS } from "../../graphql/queries";
import PostCollectionMemberForm from "../forms/PostCollectionsEditorForm";
import { BRAND_REVIEW_ALLOW_LIST_BRAND_IDS } from "../../config/betaAllowLists";

interface NavigationItem {
  name: string;
  id?: string;
  href?: string;
  icon?: Function;
  badgeNumber?: number;
  subTabs?: NavigationItem[];
  customAction?: Function;
  minimalIndent?: boolean;
}

export default function Sidebar({
  shouldShowPausedBanner,
}: {
  shouldShowPausedBanner: boolean;
}) {
  const { user, brandId, brandRecord } = useContext(UserContext);
  const { badges } = useContext(BadgesContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentBrand, setCurrentBrand] = useState<Brand>(null);
  const [challengesSubTabsOpen, setChallengesSubTabsOpen] = useState(true);
  const [collectionsSubTabsOpen, setCollectionsSubTabOpen] = useState(true);

  const whitelistBadges = badges?.whitelistBadges?.recommended;
  const challengesBadges = badges?.challengesBadges;

  const [isCollectionEditorModalOpen, setIsCollectionEditorModalOpen] =
    useState(false);

  const initNavigation: NavigationItem[] =
    user.uid === null || user.uid === "Fvx1q2IZBWYb2aUTTRp9I6yrzm02"
      ? [{ name: "Billing", href: "/billing/plan", icon: CreditCardIcon }]
      : [
          { name: "Content", href: "/content", icon: PhotoIcon },
          { name: "Collections", href: "/content", icon: BookmarkIcon },
          {
            name: "Whitelisting",
            href: "/whitelisting",
            icon: MegaphoneIcon,
            badgeNumber: whitelistBadges,
          },
          { name: "Reports", href: "/reports", icon: ClipboardIcon },
          { name: "Billing", href: "/billing/plan", icon: CreditCardIcon },
          { name: "Creators", href: "/creators/links", icon: UsersIcon },
          {
            name: "Challenges",
            icon: StarIcon,
            subTabs: [
              { name: "Overview", href: "/challenges" },
              { name: "Your Suggestions", href: "/challengeSuggestions" },
            ],
          },
          ...(BRAND_REVIEW_ALLOW_LIST_BRAND_IDS.has(brandId)
            ? [
                {
                  name: "Video Review",
                  href: "/videoReview",
                  icon: ClipboardIcon,
                },
              ]
            : []),
        ];

  const [navigationItems, setNavigationItems] = useState(initNavigation);

  const bottomNavigation: NavigationItem[] = [
    { name: "Settings", href: "/settings/general", icon: Cog8ToothIcon },
  ];

  const { data: postCollectionsData } = useQuery(POST_COLLECTIONS, {
    variables: {
      brandId: brandId,
    },
  });
  useEffect(() => {
    const postCollections = postCollectionsData?.postCollections?.edges;
    if (!postCollections) {
      return;
    }
    let newNavItems = [...navigationItems];
    newNavItems = newNavItems.map((item) => {
      if (item.name === "Collections") {
        return {
          ...item,
          subTabs: [
            ...postCollections.map((collection) => {
              return {
                name: collection.node.title,
                id: collection.node.id,
                href: `/collection/${collection.node.id}`,
                // icon: BookmarkIcon,
                minimalIndent: true,
              };
            }),
            {
              name: "Create",
              icon: PlusIcon,
              minimalIndent: true,
              customAction: () => setIsCollectionEditorModalOpen(true),
            },
          ],
        };
      } else {
        return item;
      }
    });
    setNavigationItems(newNavItems);
  }, [postCollectionsData]);

  useEffect(() => {
    if (brandRecord) {
      let newNavigationItems = [...navigationItems];

      if (brandRecord.isChallengeReviewRequired) {
        newNavigationItems = newNavigationItems.map((item) => {
          if (item.name === "Challenges") {
            return {
              ...item,
              subTabs: [
                ...item.subTabs.filter(
                  (subTab) => subTab.name !== "Pending Approval"
                ),
                {
                  name: "Pending Approval",
                  href: "/pendingChallenges",
                  badgeNumber: challengesBadges?.pendingReviewFromBrand,
                },
              ],
            };
          } else {
            return item;
          }
        });
      }

      if (
        brandRecord.isKaleStoreEnabled === true &&
        user &&
        user.uid !== "Fvx1q2IZBWYb2aUTTRp9I6yrzm02" &&
        newNavigationItems.findIndex((item) => item.name === "Kale Store") ===
          -1
      ) {
        newNavigationItems.push({
          name: "Kale Store",
          href: "/orders",
          icon: ShoppingBagIcon,
        });
      }

      if (
        brandRecord?.shouldShowOrders &&
        newNavigationItems.findIndex((item) => item.name === "Orders") === -1
      ) {
        newNavigationItems.push({
          name: "Orders",
          href: "/orders",
          icon: ShoppingBagIcon,
        });
      }

      setNavigationItems(newNavigationItems);
      setCurrentBrand(brandRecord);
    }
  }, [brandRecord, user, badges]);

  const toggleSubTabs = (item: NavigationItem) => {
    switch (item.name) {
      case "Collections":
        setCollectionsSubTabOpen(!collectionsSubTabsOpen);
        break;
      case "Challenges":
        setChallengesSubTabsOpen(!challengesSubTabsOpen);
        break;
      default:
        break;
    }
  };

  const isSubTabsOpen = (item: NavigationItem) => {
    switch (item.name) {
      case "Collections":
        return collectionsSubTabsOpen;
      case "Challenges":
        return challengesSubTabsOpen;
      default:
        return null;
    }
  };

  const renderSidebarItem = (item: NavigationItem) => {
    return (
      <>
        <div className="flex items-center">
          {item.icon && (
            <item.icon
              className="mr-3 h-6 w-6 flex-shrink-0 text-gray-500"
              aria-hidden="true"
            />
          )}
          {item.name}
        </div>
        <Badge number={item.badgeNumber} bgColor="bg-green-900" />
      </>
    );
  };

  const renderSubTabsIcon = (item: NavigationItem) => {
    const chevronDown = <ChevronDownIcon className="h-4 w-4 text-gray-700" />;
    const chevronRight = <ChevronRightIcon className="h-4 w-4 text-gray-700" />;
    switch (item.name) {
      case "Collections":
        return collectionsSubTabsOpen ? chevronDown : chevronRight;
      case "Challenges":
        return challengesSubTabsOpen ? chevronDown : chevronRight;
      default:
        return null;
    }
  };

  return (
    <>
      <div
        className={classNames("w-full", shouldShowPausedBanner ? "pt-12" : "")}
      >
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute right-0 top-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pb-4 pt-5">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img
                        className="h-8 w-auto"
                        src="https://storage.googleapis.com/kale-public/kale-brands/kale-lockup.svg"
                        alt="Kale Dashboard"
                      />
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      {navigationItems.map((item) => (
                        <div key={item.name}>
                          {item.subTabs?.length > 0 ? (
                            <div>
                              <button
                                className="group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                                aria-controls="sub-menu-1"
                                aria-expanded="false"
                                onClick={() => toggleSubTabs(item)}
                              >
                                {renderSidebarItem(item)}
                                {renderSubTabsIcon(item)}
                              </button>
                              {isSubTabsOpen(item) && (
                                <ul className="mt-1 space-y-1" id="sub-menu-1">
                                  {item.subTabs.map((subTab) => (
                                    <li
                                      className={
                                        subTab.minimalIndent ? "pl-5" : "pl-10"
                                      }
                                      key={subTab.id ?? subTab.name}
                                    >
                                      {subTab.customAction ? (
                                        <button
                                          onClick={() => subTab.customAction()}
                                          className="group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                                        >
                                          {renderSidebarItem(subTab)}
                                        </button>
                                      ) : (
                                        <NavLink
                                          key={subTab.name}
                                          to={subTab.href}
                                          activeClassName="bg-kale-mint-400 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                          className="group flex items-center justify-between rounded-md px-2 py-2 text-sm text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                                        >
                                          {renderSidebarItem(subTab)}
                                        </NavLink>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          ) : (
                            <NavLink
                              key={item.name}
                              to={item.href}
                              activeClassName="bg-kale-mint-400 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                              className="group flex items-center justify-between rounded-md px-2 py-2 text-sm text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                            >
                              {renderSidebarItem(item)}
                            </NavLink>
                          )}
                        </div>
                      ))}
                    </nav>
                  </div>
                  <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                    <Link to="/settings" className="group block flex-shrink-0">
                      <div className="flex items-center">
                        <div>
                          {currentBrand && currentBrand.logoUrl ? (
                            <img
                              className="inline-block h-10 w-10 rounded-full"
                              src={currentBrand.logoUrl}
                              alt=""
                            />
                          ) : (
                            <UserIcon className="inline-block h-10 w-10 rounded-full" />
                          )}
                        </div>
                        <div className="ml-3">
                          <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                            {currentBrand?.name}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          className={classNames(
            "hidden md:fixed md:inset-y-0 md:flex md:w-56 md:flex-col",
            shouldShowPausedBanner ? "pt-12" : ""
          )}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div className="flex flex-1 flex-col overflow-y-auto pb-4 pt-5">
              <div className="flex flex-shrink-0 items-center px-4">
                <img
                  className="h-8 w-auto"
                  src="https://storage.googleapis.com/kale-public/kale-brands/kale-lockup.svg"
                  alt="Kale Dashboard"
                />
              </div>
              <nav className="mt-5 flex-1 space-y-1 bg-white px-2">
                {navigationItems.map((item) => (
                  <div key={item.id ?? item.name}>
                    {item.subTabs?.length > 0 ? (
                      <div>
                        <button
                          className="group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                          aria-controls="sub-menu-1"
                          aria-expanded="false"
                          onClick={() => toggleSubTabs(item)}
                        >
                          {renderSidebarItem(item)}
                          {renderSubTabsIcon(item)}
                        </button>
                        {isSubTabsOpen(item) && (
                          <ul className="mt-1 space-y-1" id="sub-menu-1">
                            {item.subTabs.map((subTab) => (
                              <li
                                className={
                                  subTab.minimalIndent ? "pl-5" : "pl-10"
                                }
                                key={subTab.id ?? subTab.name}
                              >
                                {subTab.customAction ? (
                                  <button
                                    onClick={() => subTab.customAction()}
                                    className="group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                                  >
                                    {renderSidebarItem(subTab)}
                                  </button>
                                ) : (
                                  <NavLink
                                    key={subTab.id ?? subTab.name}
                                    to={subTab.href}
                                    activeClassName="bg-kale-mint-400 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                    className="group flex items-center justify-between rounded-md px-2 py-2 text-sm text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                                  >
                                    {renderSidebarItem(subTab)}
                                  </NavLink>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ) : (
                      <NavLink
                        key={item.id ?? item.name}
                        to={item.href}
                        activeClassName="bg-kale-mint-400 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        className="group flex items-center justify-between rounded-md px-2 py-2 text-sm text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                      >
                        {renderSidebarItem(item)}
                      </NavLink>
                    )}
                  </div>
                ))}
              </nav>
              <nav className="flex w-full space-y-1 bg-white px-2">
                {bottomNavigation.map((item) => (
                  <NavLink
                    key={item.id ?? item.name}
                    to={item.href}
                    activeClassName="bg-kale-mint-400 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    className="group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                  >
                    <item.icon
                      className="mr-3 h-6 w-6 flex-shrink-0 text-gray-500"
                      aria-hidden="true"
                    />
                    {item.name}
                  </NavLink>
                ))}
              </nav>
            </div>
            <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
              <Link
                to="/settings/general"
                className="group block w-full flex-shrink-0"
              >
                <div className="flex items-center">
                  <div>
                    {currentBrand && currentBrand.logoUrl ? (
                      <img
                        className="inline-block h-9 w-9 rounded-full"
                        src={currentBrand.logoUrl}
                        alt=""
                      />
                    ) : (
                      <UserIcon className="inline-block h-9 w-9 rounded-full" />
                    )}
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                      {currentBrand?.name}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-1 md:pl-56">
          <div className="sticky top-0 z-10 bg-white pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <Modal
        className="w-full max-w-xl"
        isModalOpen={isCollectionEditorModalOpen}
        onClose={() => {
          setIsCollectionEditorModalOpen(false);
        }}
      >
        <PostCollectionMemberForm
          closeModal={() => {
            setIsCollectionEditorModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
}

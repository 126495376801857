import { Brand, Challenge, Spinner } from "@kalecard/common";
import { REVIEW_CHALLENGE } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { classNames } from "../../utils/style";

interface ReviewChallengeInterface {
  brandId: string;
  challenge: Challenge;
  isApproved: boolean;
  onSubmit?: (reviewedChallenge: Challenge) => void;
}

interface ReviewChallengeFormInputs {
  comments?: string;
}

export default function ReviewChallengeForm({
  brandId,
  challenge,
  isApproved,
  onSubmit,
}: ReviewChallengeInterface) {
  const [reviewChallenge] = useMutation(REVIEW_CHALLENGE);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { reset, register, handleSubmit, watch, getValues, setValue } =
    useForm<ReviewChallengeFormInputs>();

  const submit: SubmitHandler<ReviewChallengeFormInputs> = async (
    data: ReviewChallengeFormInputs
  ) => {
    setIsLoading(true);
    try {
      const result = await reviewChallenge({
        variables: {
          brandId: brandId,
          challengeId: challenge.id,
          comments: data.comments,
          isApproved: isApproved,
        },
      });
      onSubmit(result.data.reviewChallenge);
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setIsLoading(false);
  };

  return (
    <form className="w-full space-y-2">
      <div className="flex flex-col space-y-2">
        <div>
          <p className="text-sm text-gray-700">{challenge.description}</p>
          <p className="text-sm text-gray-500">{challenge.tagRequirements}</p>
        </div>
        {/* Comments */}
        {!isApproved && (
          <div className="pt-2">
            <label
              htmlFor="comments"
              className="block text-sm font-medium text-gray-700"
            >
              Request Edits:
            </label>
            <div className="mt-1 flex flex-col">
              <div className="flex flex-row">
                <textarea
                  rows={3}
                  {...register("comments", { required: !isApproved })}
                  name="comments"
                  id="comments"
                  className="block w-full flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={classNames(
            "flex space-x-2",
            isApproved ? "justify-center pt-2" : "justify-end pt-5"
          )}
        >
          {isLoading && (
            <div className="flex flex-wrap content-center justify-center">
              <Spinner size="h-6 w-6" />
            </div>
          )}
          <div>
            <button
              type="button"
              onClick={handleSubmit(submit)}
              disabled={isLoading}
              className={classNames(
                isApproved
                  ? "bg-green-700 hover:bg-green-800 focus:ring-green-500"
                  : "bg-indigo-700 hover:bg-indigo-800 focus:ring-indigo-500",
                "inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
              )}
            >
              {isApproved ? "Approve Challenge" : "Submit"}
            </button>
          </div>
        </div>
        <div className="mt-2 flex justify-end">
          <p className="font-bold text-red-500">{error}</p>
        </div>
      </div>
    </form>
  );
}

import { Fragment, useContext } from "react";
import { classNames } from "../../utils/style";
import AllContent from "./AllContent";
import { ContentContext } from "../../providers/ContentProvider";
import { Challenge } from "@kalecard/common";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { useHistory } from "react-router";
import { sortingTypes } from "./SortingTypes";
import { Link } from "react-router-dom";

export default function BrandsContent() {
  const {
    state: { sortingType, challengeId, selectableChallenges },
    dispatch: contentDispatch,
  } = useContext(ContentContext);
  const history = useHistory();

  const placeHolderChallenge = {
    description: "Filter by Active Challenge",
    id: "-1",
    tagRequirements: "",
  };
  const challengeOptions = [placeHolderChallenge, ...selectableChallenges];

  const selectedChallenge = challengeId
    ? selectableChallenges.find((challenge) => challenge?.id === challengeId) ??
      placeHolderChallenge
    : placeHolderChallenge;

  const selectChallenge = (challenge: Challenge) => {
    history.push({
      pathname: "/challenges/" + challenge.id,
    });
  };

  const renderChallengesSelectMenu = (
    <Listbox value={selectedChallenge} onChange={selectChallenge}>
      <div className="relative mt-1">
        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-sm shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
          <span className="inline-flex w-full truncate">
            <span className="truncate">
              {selectedChallenge && selectedChallenge.description}
            </span>
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {challengeOptions.map((challenge) => {
              return (
                <Listbox.Option
                  key={challenge.id}
                  disabled={challenge.id === "-1"}
                  className={({ active }) =>
                    classNames(
                      active
                        ? "bg-indigo-600 text-white"
                        : challenge.id === "-1"
                        ? "text-gray-400"
                        : "text-gray-900",
                      "relative cursor-default select-none py-2 pl-3 pr-9"
                    )
                  }
                  value={challenge}
                >
                  {({ selected, active }) => (
                    <>
                      <div className="flex">
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "truncate"
                          )}
                        >
                          {challenge.description}
                        </span>
                      </div>
                      {selected ? (
                        <span
                          className={classNames(
                            active ? "text-white" : "text-indigo-600",
                            "absolute inset-y-0 right-0 flex items-center pr-4"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              );
            })}
            <div className="py-2 pl-3 pr-9">
              <Link
                to="/challenges"
                className="text-sm font-semibold underline"
              >
                View all Challenges
              </Link>
            </div>
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );

  return (
    <>
      <div>
        <div className="mx-6 mb-2 flex items-center justify-between border-b">
          {sortingTypes(contentDispatch, sortingType)}
          <div className="w-60">
            {selectableChallenges.length > 0 && (
              <div>{renderChallengesSelectMenu}</div>
            )}
          </div>
        </div>
      </div>
      <AllContent />
    </>
  );
}

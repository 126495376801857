import { useMutation, useQuery } from "@apollo/client";
import { WhitelistContentData, Spinner } from "@kalecard/common";
import { useContext, useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import WhitelistAccessRequestForm from "../components/forms/WhitelistAccessRequestForm";
import WhitelistContentListComponent from "../components/lists/elements/WhitelistContentListComponent";
import { WhitelistBadges, WHITELIST_CONTENT } from "../graphql/queries";
import { REQUEST_WHITELISTING_ACCESS } from "../graphql/mutations";
import { UserContext } from "../providers/UserProvider";
import { BadgesContext } from "../providers/BadgesProvider";
import Badge from "../components/utils/Badge";
import KaleBrandsHelmet from "../components/utils/KaleBrandsHelmet";

const pathsToTabs = {
  recommended: {
    name: "Recommended",
    description:
      "These are high quality videos that Kale recommends should be run as ads.",
    states: ["RECOMMENDED"],
    path: "recommended",
  },
  processing: {
    name: "Processing",
    description:
      "These are posts you've requested to whitelist, but are pending approval from the creator.",
    states: ["NEW", "BRAND_ACCEPTED", "CREATOR_ACCEPTED", "IN_REVIEW"],
    path: "processing",
  },
  ready: {
    name: "Ready",
    description:
      "These are posts ready to be whitelisted! An email was sent to you with the boost code.",
    states: ["READY"],
    path: "ready",
  },
  "in-progress": {
    name: "In Progress",
    description: "These are posts currently being run as ads.",
    states: ["IN_PROGRESS"],
    path: "in-progress",
  },
  completed: {
    name: "Completed",
    description: "These are posts you've whitelisted in the past.",
    states: ["COMPLETE", "COMPLETED"],
    path: "completed",
  },
};

const getBadgeCount = (whitelistBadges: WhitelistBadges, states: string[]) => {
  let count = 0;
  if (!whitelistBadges) return count;

  states?.forEach((state) => {
    const stateCamelCase = state
      .toLowerCase()
      .replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace("_", ""));
    count += whitelistBadges[stateCamelCase] || 0;
  });
  return count;
};

export default function WhitelistContentPage() {
  let location = useLocation();
  const paths = location.pathname.split("/");
  const initialPath = paths[paths.length - 1];

  const [edges, setEdges] = useState([]);
  const [after, setAfter] = useState(0);
  const [currPath, setCurrPath] = useState(initialPath);
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const { badges } = useContext(BadgesContext);
  const whitelistBadges = badges?.whitelistBadges;
  const history = useHistory();

  if (!pathsToTabs[currPath]) {
    history.push("/whitelisting/recommended");
  }
  const currPathData = pathsToTabs[currPath];

  const { brandId, brandRecord } = useContext(UserContext);
  const afterParam = 20;

  const onQueryComplete = (data) => {
    setEdges(data.whitelistContent.edges);
    setAfter(after + afterParam);
  };

  const [requestWhitelistingAccess] = useMutation(REQUEST_WHITELISTING_ACCESS);

  const { loading, data, fetchMore } = useQuery<WhitelistContentData>(
    WHITELIST_CONTENT,
    {
      variables: {
        brandId,
        states: pathsToTabs[currPath]?.states,
        first: afterParam,
        after: "0",
      },
      onCompleted: (data) => onQueryComplete(data),
      fetchPolicy: "network-only",
    }
  );

  var listFooter = null;
  if (data?.whitelistContent.pageInfo?.hasNextPage) {
    listFooter = (
      <button
        className="rounded border border-green-500 bg-transparent py-2 px-6 text-center font-semibold text-green-700 hover:border-transparent hover:bg-green-500 hover:text-white"
        onClick={() =>
          fetchMore({
            variables: {
              brandId: brandId,
              states: currPathData.states,
              first: afterParam,
              after: after.toString(),
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              const previousEdges = prev?.whitelistContent.edges;
              const fetchMoreEdges = fetchMoreResult?.whitelistContent.edges;

              fetchMoreResult.whitelistContent.edges = [
                ...previousEdges,
                ...fetchMoreEdges,
              ];
              fetchMoreResult.whitelistContent.pageInfo =
                fetchMoreResult.whitelistContent.pageInfo;

              return { ...fetchMoreResult };
            },
          })
        }
      >
        Load more
      </button>
    );
  }

  if (loading) {
    listFooter = (
      <div className="flex flex-wrap content-center">
        <Spinner size="h-6 w-6" />
      </div>
    );
  }

  const hasWhitelistPermission =
    brandRecord && brandRecord.hasWhitelistPermission;

  const requestWhitelistAccess = async () => {
    const variables = {
      brandId,
    };
    try {
      const result = await requestWhitelistingAccess({ variables });
      if (result.data.requestWhitelistingAccess == true) {
        setSuccessMessage(
          "Your request was sent successfully to Kale! A representative will contact you soon."
        );
        setError(null);
      } else {
        // Set an error
        console.error("Error requesting whitelisting access");
        setError(
          "Something went wrong, try refreshing this page or try again later."
        );
      }
    } catch (err) {
      console.error(err);
      setError("Something went wrong, please try again later.");
    }
  };

  useEffect(() => {
    const paths = location.pathname.split("/");
    const path = paths[paths.length - 1];
    setCurrPath(path);
  }, [location]);

  return (
    <>
      <KaleBrandsHelmet pageName="Whitelisting" />
      <div className="flex flex-row items-center space-x-4 px-2 pb-2 sm:px-6 md:px-4">
        <span className="text-2xl font-semibold text-gray-900">
          Whitelist Content
        </span>
        <a
          href="https://kalecard.notion.site/kalecard/Whitelisting-with-Kale-df776935e4e94618afc7fa6f0f06d32a"
          target="_blank"
          className="text-xs font-semibold"
        >
          <QuestionMarkCircleIcon
            className="block h-5 w-5 rounded-full border border-blue-600 text-blue-600"
            aria-hidden="true"
          />
        </a>
        <div className="flex flex-row items-center space-x-4 pl-6">
          {!hasWhitelistPermission && (
            <button
              type="button"
              onClick={requestWhitelistAccess}
              className="max-w-15 place-self-center rounded-md border border-transparent bg-kale-mint-500 py-2 px-4 text-sm font-medium text-gray-900 shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-kale-green-500 focus:ring-offset-2"
            >
              Request Whitelisting Access
            </button>
          )}
          {successMessage && (
            <div className="text-sm text-kale-green-500">{successMessage}</div>
          )}
          {error && <div className="text-sm text-red-500">{error}</div>}
        </div>
      </div>
      <div className="border-b border-gray-200 px-4 pt-2 sm:px-6 md:px-4">
        <div className="block">
          <nav className="-mb-px flex space-x-8">
            {Object.values(pathsToTabs).map((tab) => (
              <NavLink
                key={tab.name}
                to={`/whitelisting/${tab.path}`}
                className={(isActive) =>
                  (isActive
                    ? "border-b-2 border-black text-black "
                    : "text-gray-500 hover:border-b-2 hover:border-gray-500 hover:text-gray-600") +
                  "whitespace-nowrap px-1 pb-4 text-sm font-medium"
                }
              >
                <div className="flex flex-row items-center justify-center space-x-2">
                  <span>{tab.name}</span>
                  <Badge
                    number={getBadgeCount(whitelistBadges, tab.states)}
                    bgColor={
                      currPath === tab.path ? "bg-green-900" : "bg-gray-500"
                    }
                  />
                </div>
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      <div className="p-4 text-sm">{pathsToTabs[currPath]?.description}</div>
      <div className="mx-4">
        {edges.length > 0 && (
          <ul role="list" className="flex flex-row flex-wrap">
            {edges.map((edge, index) => (
              <WhitelistContentListComponent
                key={index}
                whitelistContent={edge}
                showActionButtons={
                  hasWhitelistPermission && currPath === "recommended"
                }
              />
            ))}
          </ul>
        )}
        {brandRecord?.hasWhitelistPermission === false && (
          <div className="min-h-screen pt-20 sm:max-w-xl">
            <WhitelistAccessRequestForm brandId={brandId} />
          </div>
        )}
        <div className="flex justify-center">{listFooter}</div>
      </div>
    </>
  );
}

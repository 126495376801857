import { useContext, useState } from "react";
import { UserContext } from "../providers/UserProvider";
import UpdateBrandDetailsForm, {
  isBrandDetailsStepComplete,
} from "./forms/UpdateBrandDetailsForm";
import BrandLandingPageForm, {
  BrandLandingPageQuery,
  isBrandLandingPageStepComplete,
} from "./forms/BrandLandingPageForm";
import { Brand, Spinner } from "@kalecard/common";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@apollo/client";
import { BRAND, BRAND_LANDING_PAGE } from "../graphql/queries";
import {
  BrandPaymentMethods,
  isBrandPaymentMethodsComplete,
} from "./billing/MyPlanPage";
import { BrandSetupStepButtonType } from "./setup/BrandSetupStepButton";
import { Tooltip } from "./Tooltip";

export default function BrandSetup() {
  const { brandId, updateBrandRecord } = useContext(UserContext);

  interface BrandData {
    brand: Brand;
  }
  const { data, loading } = useQuery<BrandData>(BRAND, {
    variables: {
      id: brandId,
    },
  });

  const steps = [
    { name: "details", required: true },
    { name: "landing page", required: false },
    { name: "billing", required: data?.brand?.isSelfService },
  ];

  const { data: brandLandingPageData } = useQuery<BrandLandingPageQuery>(
    BRAND_LANDING_PAGE,
    {
      skip: !data.brand,
      variables: { referralCode: data?.brand?.referralCode },
    }
  );

  const [currentStep, setCurrentStep] = useState("details");

  const setNextStep = () => {
    const index = steps.findIndex((s) => s.name === currentStep);
    if (index < steps.length - 1) {
      setCurrentStep(steps[index + 1].name);
    } else if (index === steps.length - 1) {
      updateBrandRecord(brandId);
    }
  };

  const getStepForm = (step: string, brand: Brand) => {
    const buttonType =
      step !== steps[steps.length - 1].name
        ? BrandSetupStepButtonType.SAVE_AND_CONTINUE
        : BrandSetupStepButtonType.SAVE;
    const showSavePaymentMethodButton =
      !steps.find((tempStep) => tempStep.name === step).required ||
      isBrandPaymentMethodsComplete(brand);

    switch (step) {
      case "details":
        return (
          <UpdateBrandDetailsForm
            buttonType={buttonType}
            onSave={setNextStep}
            showRequiredIndicator={true}
            disableSaveOnIncomplete={true}
          />
        );
      case "landing page":
        return (
          <BrandLandingPageForm
            brand={data.brand}
            buttonType={buttonType}
            onSave={setNextStep}
            showCancelButton={false}
          />
        );
      case "billing":
        return (
          <BrandPaymentMethods
            brand={data.brand}
            savePaymentMethodsButtonData={{
              buttonType: BrandSetupStepButtonType.SAVE,
              shouldShowButton: showSavePaymentMethodButton,
              onClick: setNextStep,
            }}
          />
        );
    }
  };

  const getStepComplete = (step: string, brand: Brand) => {
    switch (step) {
      case "details":
        return isBrandDetailsStepComplete(brand);
      case "landing page":
        return isBrandLandingPageStepComplete(
          brandLandingPageData?.brandLandingPage
        );
      case "billing":
        return isBrandPaymentMethodsComplete(brand);
    }
  };

  return !loading && data ? (
    <div className="space-y-4">
      {/* Steps progress bar */}
      <p className="text-center font-medium">Complete your setup:</p>
      <div className="grid grid-cols-5">
        <div className="col-span-3 col-start-2">
          <div className="flex items-center justify-center">
            {steps.map((step, index) => {
              const isPastStep =
                index <=
                steps.findIndex((tempStep) => tempStep.name === currentStep);
              return (
                <div className="flex items-center">
                  {index !== 0 && (
                    <div
                      key={"line" + index}
                      className="mx-3 h-0.5 w-32 bg-gray-300"
                    ></div>
                  )}
                  <div
                    className={`flex h-10 w-10 items-center justify-center rounded-full text-xs ${
                      isPastStep
                        ? "bg-kale-mint-500 font-medium"
                        : "bg-gray-200"
                    }`}
                  >
                    {index + 1}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="grid grid-cols-3">
            {steps.map((step) => {
              const isCurrentStep = step.name === currentStep;
              const isComplete = getStepComplete(step.name, data.brand);
              return (
                <div className={`flex items-center justify-center space-x-1`}>
                  <div
                    className={`text-center text-sm capitalize ${
                      isCurrentStep ? "font-medium" : ""
                    }`}
                  >
                    {step.name}
                  </div>
                  {!isComplete && (
                    <Tooltip message={"Missing information"}>
                      <ExclamationCircleIcon
                        color="orange"
                        className="h-6 w-6"
                      />
                    </Tooltip>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Data */}
      <div
        key={currentStep + "form"}
        className="w-full rounded-md border border-gray-200 p-4"
      >
        {getStepForm(currentStep, data.brand)}
      </div>
    </div>
  ) : (
    <div className="flex flex-wrap content-center justify-center pr-2">
      <Spinner size="h-6 w-6" />
    </div>
  );
}
